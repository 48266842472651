export default {
  referenceKey: 'E',
  id: 'E',
  title: 'Nitrate intake and the risk of thyroid cancer and thyroid disease',
  datePublished: '2010-May',
  journal: 'Epidemiology',
  authorPrimary: 'Ward',
  authors:
    'Mary H Ward, Briseis A Kilfoy, Peter J Weyer, Kristin E Anderson, Aaron R Folsom, James R Cerhan',
  authorsHeading: 'Ward',
  websites: ['https://pubmed.ncbi.nlm.nih.gov/20335813'],
  data: '',
  nitrateLevel: 5,
  diseases: ['thyroid cancer'],
  healthRisk: 'thyroid cancer',
  citation: '',
  type: 'S',
  pages: 14,
  url: '/articles/e.pdf',
  keywords: [],
  abstract: `<p><b>Background:</b> Nitrate is a contaminant of drinking water in agricultural areas and is found at high levels in some vegetables. Nitrate competes with uptake of iodide by the thyroid, thus potentially affecting thyroid function.</p>
<br/>
<p><b>Methods:</b> We investigated the association of nitrate intake from public water supplies and diet with the risk of thyroid cancer and self-reported hypothyroidism and hyperthyroidism in a cohort of 21,977 older women in Iowa who were enrolled in 1986 and who had used the same water supply for >10 years. We estimated nitrate ingestion from drinking water using a public database of nitrate measurements (1955-1988). Dietary nitrate intake was estimated using a food frequency questionnaire and levels from the published literature. Cancer incidence was determined through 2004.</p>
<br/>
<p><b>Results:</b> We found an increased risk of thyroid cancer with higher average nitrate levels in public water supplies and with longer consumption of water exceeding 5 mg/L nitrate-N (for >or=5 years at >5 mg/L, relative risk [RR] = 2.6 [95% confidence interval (CI) = 1.1-6.2]). We observed no association with prevalence of hypothyroidism or hyperthyroidism. Increasing intake of dietary nitrate was associated with an increased risk of thyroid cancer (highest vs. lowest quartile, RR = 2.9 [1.0-8.1]; P for trend = 0.046) and with the prevalence of hypothyroidism (odds ratio = 1.2 [95% CI = 1.1-1.4]), but not hyperthyroidism.</p>
<br/>
<p><b>Conclusions:</b> Nitrate may play a role in the etiology of thyroid cancer and warrants further study.</p>`,

  excerpts: [
    'We found an increased risk of thyroid cancer with higher average nitrate levels in public water supplies and with longer consumption of water exceeding 5 mg/L nitrate-N (for >or=5 years at >5 mg/L.'
  ]
};
